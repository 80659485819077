<template>
  <div class="info">
    <p class="txt">您的申请资料已提交，请保持手机畅通，组委会将在收到资料的7个工作日内给您回电，并确认您是否申请到“优秀指导教师”奖项。</p>
    <p class="txt">回电时间将在上午10点至12点和下午2点至5点。</p>
    <el-button class="btn" type="primary" @click="callMe">或者直接联系我们</el-button>
  </div>
</template>
<script>

export default ({
  methods: {
    callMe() {
      window.location.href = 'tel://025-85209887'
    }
  }
})
</script>

<style scoped>
.info {
  width: 375px;
  margin: 0 auto;
  color: #000;
  font-size: 15px;
  padding-top: 30px;
}
.txt {
  padding: 0 20px 0 20px;
  text-align: left;
  margin-bottom: 5px;
  text-indent : 32px;
}
.btn {
  margin-top: 30px;
  width: 200px;
}
</style>